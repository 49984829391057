<template>
    <div class="">

        <div class="">
            <custom-alert v-if="displayAlert==true" :message="alertMessage"
                          v-on:showedAlert="resetAlert"></custom-alert>
            <div class="profile-body pt-3">
                <div class="row">
                    <div class="col-md-6 mb-2">
                      <div class="card" v-if="getSiteProps('general.club_auto_renewal_option') && subdomain !=='shooting'">
                        <div class="card-header">
                          <div class="d-flex bd-highlight">
                            <div class="p-2 flex-grow-1 bd-highlight">
                              <div class="header-title">
                                <h3>Auto Renewal</h3>
                              </div>
                            </div>
                            <div class="p-2 bd-highlight">
                              <div  class="">
                                <b-form-checkbox
                                    v-model="autoRenew"
                                    name="autorenewal"
                                    switch
                                    value="1"
                                    size="lg"
                                    :checked="autoRenew"
                                    @change="changeAutoRenewal($event)"
                                ></b-form-checkbox>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="card-body">
                          <div class="col-12" v-show="CardInfo.cardNumber">
                            <div class="row alert alert-secondary">
                              <div class="col-1">
                                <b-badge>{{ CardInfo.cardType }}</b-badge>
                              </div>
                              <div class="col-8"><p class="m-0 p-0">{{ CardInfo.cardNumber }}</p></div>
                            </div>
                          </div>
                          <div class="col-12" v-show="!CardInfo.cardNumber">
                            <div class="row alert alert-secondary">
                              <span>Add new card for auto renewal</span>
                            </div>
                          </div>
                        </div>
                      </div>
                        <div class="card">
                            <div class="card-header">
                                <div class="header-title">
                                    <h3>Reset Password</h3>
                                </div>
                            </div>
                            <div class="card-body">
                                <div class="row">
                                    <div class="col-md-12">
                                        <div class="form-group">
                                            <label class="form-label">Existing Password<sup class="text-danger">*</sup></label>
                                            <input type="password" v-model="ResetPassword.old_password" class="form-control">
                                            <span class="text-sm text-danger">{{ errors.old_password }}</span>
                                        </div>
                                    </div>
                                    <div class="col-md-12">
                                        <div class="form-group">
                                            <label class="form-label">Change Password<sup class="text-danger">*</sup></label>
                                            <input type="password" v-model="ResetPassword.password" class="form-control">
                                            <span class="text-sm text-danger">{{ errors.password }}</span>
                                        </div>
                                    </div>
                                    <div class="col-md-12">
                                        <div class="form-group">
                                            <label class="form-label">Confirm Password<sup class="text-danger">*</sup></label>
                                            <input type="password" v-model="ResetPassword.password_confirmation" class="form-control">
                                            <span class="text-sm text-danger">{{ errors.password_confirmation }}</span>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-12">
                                        <div class="text-left topspace">
                                          
                                            <button class="btn btn-outline-primary savebtn next-step-btn"
                                                    @click.prevent="updatePassword">Save</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6 mb-2">
                        <div class="card">
                            <div class="card-header">
                                <div class="header-title">
                                    <h3>Security Questions</h3>
                                </div>
                            </div>
                            <div class="card-body">
                                <div class="row">
                                    <div class="col-md-12">
                                        <div class="form-group">
                                            <label class="form-label">What is your mother's maiden name?<sup
                                                class="text-danger">*</sup></label>
                                            <input type="text" v-model="UpdateProfile.maiden_info" class="form-control">
                                            <span class="text-sm text-danger">{{ errors.maiden_info }}</span>
                                        </div>
                                        <div class="form-group">
                                            <label class="form-label">What city where you born in?<sup
                                                class="text-danger">*</sup></label>
                                            <input type="text" v-model="UpdateProfile.birth_info" class="form-control">
                                            <span class="text-sm text-danger">{{ errors.birth_info }}</span>
                                        </div>
                                        <div class="form-group">
                                            <label class="form-label">What is the name of your first pet?<sup
                                                class="text-danger">*</sup></label>
                                            <input type="text" v-model="UpdateProfile.pet_info" class="form-control">
                                            <span class="text-sm text-danger">{{ errors.pet_info }}</span>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-12">
                                        <div class="text-left topspace">                                           
                                            <button @click.prevent="updateMemberSettingsInfo"
                                                class="btn btn-outline-primary savebtn next-step-btn">Save</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                       
                    </div>
                </div>
            </div>
            <!-- profile body end-->
        </div>

        <b-modal id="autoRenewalModal" ref="modal" ok-title="Enable" hide-footer centered title="Enable Auto Renewal"
                 @show="resetCardModal" @hidden="resetCardModal"
                 @cancel="resetRenewalStatus">
            <div class="row">
                <div class="col-md-12">
                    <div class="form-group">
                        <label class="form-label">Card Number<sup class="text-danger">*</sup></label>
                        <input type="text" maxlength="16" placeholder="1234 1234 1234 1234"
                               v-model="UpdCardInfo.cardNumber" class="form-control">
                        <small class="text-danger" v-html="cardError.cardNumber"></small>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-4">
                    <label class="form-label">Expiration Date<sup class="text-danger">*</sup></label>
                    <select id="expiration-month" required="required" v-model="UpdCardInfo.expiration_month"
                            class="form-control form-select">
                        <option value="">Month</option>
                        <option>01</option>
                        <option>02</option>
                        <option>03</option>
                        <option>04</option>
                        <option>05</option>
                        <option>06</option>
                        <option>07</option>
                        <option>08</option>
                        <option>09</option>
                        <option>10</option>
                        <option>11</option>
                        <option>12</option>
                    </select>
                    <small class="text-danger" v-html="cardError.expiration_month"></small>
                </div>
                <div class="col-md-4 pl-0 col-md-offset-1" style="margin-top: 28px;">
                    <select id="expiration-year" required="required" v-model="UpdCardInfo.expiration_year"
                            class="form-select form-control">
                        <option value="">Year</option>
                        <option v-for="year in years" :value="year" :key="year">{{ year }}</option>
                    </select>
                    <small class="text-danger" v-html="cardError.expiration_year"></small>
                </div>
                <div class="col-md-4">
                    <label class="form-label">CVV/CVC<sup class="text-danger">*</sup></label>
                    <input maxlength="4" type="password" v-model="UpdCardInfo.cvv" class="form-control">
                    <small class="text-danger" v-html="cardError.cvv"></small>
                </div>
            </div>

            <div class="modal-footer mt-3">
                <button type="button" class="btn btn-primary" @click="updateCreditCardInfo" :disabled="autoRenewProgress">
                    Enable <i class="fa fa-circle-notch fa-spin" v-if="autoRenewProgress"></i>
                </button>
            </div>
        </b-modal>


    </div>
</template>

<script>
import moment from 'moment';
import axios from 'axios';


// import clubnavigation from "../components/Navigation";
// import clubsidebar from "../components/Sidebar";
// import clubdboardfooter from "../components/Footer";
import CustomAlert from "../../../CustomAlert";

const validateName = name => {
  if (name == undefined) {
    return { valid: false, error: "This field is required" };
  } else if(!name.length || name.trim() == "") {
    return { valid: false, error: "This field is required" };
  }
  return { valid: true, error: null };
};

export default {
    name: "clubSettings",
    components: {CustomAlert},
    data(){
        return {
            profileData: {
                info: [],
            },
            UpdateProfile:{
                "maiden_info": "",
                "pet_info": "",
                "birth_info": ""
            },
            ResetPassword:{
                old_password:"",
                password:"",
                password_confirmation:"",
            },
            CardInfo:{
                cardNumber: "",
                expirationDate: "",
                cardType: ""
            },
            UpdCardInfo:{
                cardNumber: "",
                expiration_year:"",
                expiration_month:"",
                cvv:""
            },
            autoRenew:0,
            autoRenewData:{
                regId:"",
                status:"",
            },
            autoRenewProgress: false,
            cardError: {},
            errors: {},
            valid: false,
            enableAutoRenew:true,
        }
    },
    methods:{
        loadClubInfo(){
            axios.get(this.basePath+"api/club/info",{ headers:this.clubHeaders })
                .then((response)=>{
                    this.profileData.info  = response.data.data;
                    if(this.subdomain == 'alliancefastpitch' && (this.profileData.info.primary_contact==null || this.profileData.info.primary_contact.contact_type!=0))
                    {
                        this.enableAutoRenew = false;
                    } 
                    const resp  = response.data.data.security_questions;
                     this.autoRenew = this.profileData.info.auto_renew;
                      console.log('step-3');
          console.log(this.autoRenew);
                    this.autoRenewData.regId = this.profileData.info.registration.id;
                       console.log('step-4');
          console.log(this.autoRenewData.regId);
                    this.autoRenewData.status = this.profileData.info.auto_renew;
                    for (let prop in resp) {
                        if ( Object.prototype.hasOwnProperty.call(this.UpdateProfile, prop) ) {
                            this.UpdateProfile[prop] = resp[prop]
                        }
                    }
                    this.getCardInformation();
                }).catch((error) =>{
                console.log(error);
            });
        },
        getCardInformation(){
            axios.get(this.basePath+"api/club/creditcard_info/"+this.profileData.info.registration.id,{ headers:this.clubHeaders })
                .then((response)=>{
                    this.CardInfo = response.data.data;
                }).catch((error) =>{
                console.log(error);
            });
        },
        updateCreditCardInfo(){
            if(this.validateCard()){
                return true;
            }

              if(this.profileData.info.registration.customerprofileid != null && this.profileData.info.registration.paymentprofileid != null){
                  this.autoRenewProgress = true;
                this.UpdCardInfo.auto_renew = 1;
            axios.post(this.basePath+"api/club/creditcard_info/"+this.profileData.info.registration.id, this.UpdCardInfo,{ headers:this.clubHeaders })
                .then(function (response) {
                    if(response.data.status == "success"){
                        this.getCardInformation();
                        this.loadClubInfo();
                        this.alertMessage = "Auto renewal enabled successfully";
                        this.displayAlert = true;
                    } else {
                        if(this.subdomain=='alliancefastpitch')
                        {
                            this.loadClubInfo();
                            this.alertMessage = "Card Info updated";
                            this.displayAlert = true;
                        }else{
                            this.alertMessage = response.data.message;
                            alert(this.alertMessage);
                        }                        
                    }
                    this.$bvModal.hide('autoRenewalModal');
                    this.autoRenewProgress = false;
                }.bind(this))
                .catch(function (error) {
                    console.log(error);
                    this.autoRenewProgress = false;
                });
              }else{
                this.createCreditCardInfo();
              }
        },
        validateCard(){
            var error_flag = 0;
            this.cardError = {};
            if(this.UpdCardInfo.cardNumber == ""){
                this.cardError.cardNumber = "Card Number Required";
                error_flag++;
            }
            if(this.UpdCardInfo.expiration_year == ""){
                this.cardError.expiration_year = "Year Required";
                error_flag++;
            }
            if(this.UpdCardInfo.expiration_month == ""){
                this.cardError.expiration_month = "Month Required";
                error_flag++;
            }
            if(this.UpdCardInfo.cvv == ""){
                this.cardError.cvv = "CVV Required";
                error_flag++;
            }

            if(error_flag == 0){
                if(this.UpdCardInfo.expiration_year == new Date().getFullYear()){
                    if(this.UpdCardInfo.expiration_month < moment(new Date()).format("MM")){
                        this.cardError.cardNumber = "Card is Expired";
                        error_flag++;
                    }
                } else if(this.UpdCardInfo.expiration_year < new Date().getFullYear()) {
                    this.cardError.cardNumber = "Card is Expired";
                    error_flag++;
                }
            }

            return error_flag;
        },
        createCreditCardInfo(){
                  console.log(this.UpdCardInfo);
                  this.UpdCardInfo.auto_renew = 1;
                  this.autoRenewProgress = true;
                axios.post(this.basePath+"api/club/creditcard_info_create/"+this.profileData.info.registration.id, this.UpdCardInfo,{ headers:this.clubHeaders })
                    .then(function (response) {
                        if(response.data.status == "success"){
                            this.loadClubInfo();
                            this.alertMessage = "Auto renewal enabled successfully";
                             this.displayAlert = true;
                        } else {
                            if(this.subdomain=='alliancefastpitch')
                            {
                                this.loadClubInfo();
                                this.alertMessage = "Card Info updated";
                                this.displayAlert = true;
                            }else{
                                this.alertMessage = response.data.message;
                                alert(this.alertMessage);
                            }
                        }
                         this.$bvModal.hide('autoRenewalModal');
                        this.autoRenewProgress = false;
                    }.bind(this))
                    .catch(function (error) {
                        console.log(error);
                        this.autoRenewProgress = false;
                    });
            },
        secvalidate() {
           
          this.errors = {}
          this.valid = false;

          const validMaidenInfo = validateName(this.UpdateProfile.maiden_info);
          this.errors.maiden_info = validMaidenInfo.error;
         
          const validBirthInfo = validateName(this.UpdateProfile.birth_info);
          this.errors.birth_info = validBirthInfo.error;

          const validPetInfo = validateName(this.UpdateProfile.pet_info);
          this.errors.pet_info = validPetInfo.error;

           if (validMaidenInfo.valid && validBirthInfo.valid && validPetInfo.valid)
           {
               this.valid = true;
           }
           return this.valid;
        },
        updateMemberSettingsInfo(){
            if(this.secvalidate()){
                axios.post(this.basePath+'api/club/sec_settings', this.UpdateProfile,{ headers:this.clubHeaders })
                .then(function (response) {
                    if(response.status=='Success')
                    {
                        this.alertMessage = "Club security settings changed successfully";
                    }else{
                        this.alertMessage = response.data.message;   
                    }   
                    
                    this.displayAlert = true;
                    this.loadClubInfo();
                }.bind(this))
                .catch(function (error) {
                    console.log(error);
                });
            }
        },
        validate() {
            this.errors = {}
            this.valid = false;

            const validOldPassword = validateName(this.ResetPassword.old_password);
            this.errors.old_password = validOldPassword.error;

            const validPassword = validateName(this.ResetPassword.password);
            this.errors.password = validPassword.error;

            const validCPassword = validateName(this.ResetPassword.password_confirmation);
            this.errors.password_confirmation = validCPassword.error;

            if (validOldPassword.valid && validPassword.valid && validCPassword.valid)
            {
                this.valid = true;
            }
            return this.valid;
        },
        updatePassword(){
            if(this.validate()){
                axios.post(this.basePath+'api/club/change-password', this.ResetPassword,{ headers:this.clubHeaders })
                .then(function (response) {
                    this.ResetPassword.old_password='';
                    this.ResetPassword.password='';
                    this.ResetPassword.password_confirmation='';
                    if(response.status=='Success')
                    {
                        this.alertMessage = "Club Password changed successfully";
                    }else{
                        this.alertMessage = response.data.message;   
                    }                    
                    this.displayAlert = true;
                }.bind(this))
                .catch(function (error) {
                    console.log(error);
                });
            }
        },
        resetAlert(){
            this.displayAlert = false;
        },
        changeAutoRenewal(val){
            console.log(val);
      
      if(val) {
        this.boxOne = ''
         console.log(this.boxOne);
        this.$bvModal
          .msgBoxConfirm("Are you sure want to enable the Auto-Renewal?", {
             noCloseOnBackdrop: true,
           noCloseOnEsc: true,
            title: "Please Confirm",
            size: "md",
            buttonSize: "sm",
            okVariant: "danger",
            okTitle: "YES",
            cancelVariant: "grey",
            cancelTitle: "NO",
            footerClass: "p-2",
            hideHeaderClose: false,
            centered: true,
          })
          .then((value) => {       
              this.boxOne = value
              console.log( this.boxOne );
           if(this.boxOne == false){
               console.log( this.boxOne );
               this.$bvModal.hide('autoRenewalModal');
           }else if(this.boxOne == null){
                console.log( this.boxOne );
               this.$bvModal.hide('autoRenewalModal');
               }else{

             console.log( this.boxOne );
              this.$nextTick(() => {
               this.$bvModal.show('autoRenewalModal');
               });
           }
            
          })
          .catch((err) => {
            console.log(err);
          });
      } else{
                this.$bvModal.msgBoxConfirm('Are you sure want to disable the Auto-Renewal?', {
                    title: 'Please Confirm',
                    size: 'md',
                    buttonSize: 'sm',
                    okVariant: 'danger',
                    okTitle: 'YES',
                    cancelTitle: 'NO',
                    footerClass: 'p-2',
                    hideHeaderClose: false,
                    centered: true
                })
                    .then(value => {
                        if(String(value)=="true") {
                            this.autoRenewData.status=null;
                            axios.post(this.basePath + 'api/club/auto-renew-status', this.autoRenewData,{ headers:this.clubHeaders })
                                .then(function (response) {
                                    this.alertMessage = "Auto renewal disabled successfully";
                                    this.displayAlert = true;
                                    this.getCardInformation();
                                    this.loadClubInfo();
                                }.bind(this))
                                .catch(function (error) {
                                    console.log(error);
                                });
                            console.log("disabled");
                        }else{
                            this.autoRenew=this.profileData.info.auto_renew;
                        }
                    })
                    .catch(err => {
                        console.log(err);
                    })
            }
        },
        resetCardModal(){
            this.UpdCardInfo.cardNumber="";
            this.UpdCardInfo.expiration_year="";
            this.UpdCardInfo.expiration_month="";
            this.UpdCardInfo.cvv="";
            this.resetRenewalStatus();
        },
        resetRenewalStatus(){
            this.autoRenew=this.profileData.info.auto_renew;
        },
    },
    mounted() {
        this.loadClubInfo(); 
    },
    computed : {
        years () {
            const year = new Date().getFullYear()
            return Array.from({length: 12}, (value, index) => year + index)
        }
    }
}

</script>
